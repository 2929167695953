<template>
  <button
    type="button"
    class="p-0 px-2 m-0 translate-middle bg-secondary-subtle"
    @click="togglePassword"
  >
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      v-if="isShow"
    >
      <path
        stroke="currentColor"
        stroke-width="2"
        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
      />
      <path
        stroke="currentColor"
        stroke-width="2"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      v-else
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    togglePassword() {
      this.isShow = !this.isShow;
      this.$emit("isPasswordShow", this.isShow);
    },
  },
};
</script>

<style scoped>
button {
  width: max-content;
  position: absolute;
  height: 100%;
  border: none;
  border-radius: 0 5px 5px 0;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
button:focus {
  outline: none;
}
</style>
