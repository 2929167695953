<template>
  <div class="d-flex justify-content-center align-items-center mt-3">
    <div>
      <ul class="pagination">
        <!-- Tombol sebelumnya -->
        <li class="page-item" :class="{ disabled: currentPage <= 1 }">
          <button
            class="page-link"
            @click.prevent="onPageChange(currentPage - 1)"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m15 19-7-7 7-7"
              />
            </svg>
          </button>
        </li>

        <!-- Halaman pertama -->
        <li class="page-item" :class="{ active: currentPage === 1 }">
          <button class="page-link" @click.prevent="onPageChange(1)">1</button>
        </li>

        <!-- Ellipsis jika diperlukan -->
        <li v-if="currentPage > 3" class="page-item disabled">
          <span class="page-link">...</span>
        </li>

        <!-- Halaman sebelum halaman saat ini jika ada -->
        <li v-if="currentPage > 2" class="page-item">
          <button
            class="page-link"
            @click.prevent="onPageChange(currentPage - 1)"
          >
            {{ currentPage - 1 }}
        </button>
        </li>

        <!-- Halaman saat ini jika bukan halaman pertama atau terakhir -->
        <li
          v-if="currentPage !== 1"
          class="page-item active"
        >
          <button class="page-link">{{ currentPage }}</button>
        </li>

        <!-- Tombol selanjutnya -->
        <li class="page-item" :class="{ disabled: lastPage }">
          <button
            class="page-link"
            @click.prevent="onPageChange(currentPage + 1)"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m9 5 7 7-7 7"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: Boolean,
  },
  methods: {
    onPageChange(page) {
      // Mencegah navigasi ke halaman yang tidak valid
        if (page < 1) return;

      // Emit event dengan nomor halaman baru
      this.$emit("page-change", page);
    },
  },
};
</script>

<style scoped>
.page-link {
  border: none;
  outline: none;
  color: black;
}
.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  background-color: #D26B11;
  color: white;
}

.page-item.disabled .page-link {
  color: #8c969e;
}
</style>
