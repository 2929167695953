<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Ubah Password</h5>

                    <div class="settings-profile">
                      <form @submit.prevent="submitForm">
                        <div class="form-row mt-4">
                          <div class="col-12 mb-2">
                            <label for="oldPassword">Password Lama</label>
                            <div class="position-relative">
                              <input
                                style="color: #2a2e39"
                                id="oldPassword"
                                type="password"
                                class="form-control input_l"
                                placeholder="Password Lama"
                                ref="oldPassword"
                                v-model="oldPassword"
                              />
                              <TogglePassword
                                @is-password-show="togglePasswordValue"
                              />
                            </div>
                          </div>

                          <div class="col-12 mb-2">
                            <label for="newPassword">Password Baru</label>
                            <div class="position-relative">
                              <input
                                style="color: #2a2e39"
                                id="newPassword"
                                type="password"
                                class="form-control input_l"
                                placeholder="Password Baru"
                                ref="newPassword"
                                v-model="newPassword"
                              />
                              <TogglePassword
                                @is-password-show="toggleNewPasswordValue"
                              />
                            </div>
                          </div>

                          <div class="col-12 mb-2">
                            <label for="confirmPassword"
                              >Konfirmasi Password</label
                            >
                            <div class="position-relative">
                              <input
                                style="color: #2a2e39"
                                id="confirmPassword"
                                type="password"
                                class="form-control input_l"
                                placeholder="Konfirmasi Password"
                                ref="confirmPassword"
                                v-model="passwordConfirmation"
                              />
                              <TogglePassword
                                @is-password-show="toggleConfirmPasswordValue"
                              />
                            </div>
                          </div>

                          <div class="col-md-12 mt-4">
                            <input type="submit" value="Ubah" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    TogglePassword,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      oldPassword: "",
      newPassword: "",
      passwordConfirmation: "",

      isLoading: false,
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  methods: {
    togglePasswordValue(val) {
      const password = this.$refs.oldPassword;
      password.type = val ? "text" : "password";
    },

    toggleNewPasswordValue(val) {
      const password = this.$refs.newPassword;
      password.type = val ? "text" : "password";
    },

    toggleConfirmPasswordValue(val) {
      const password = this.$refs.confirmPassword;
      password.type = val ? "text" : "password";
    },

    resetForm() {
      this.oldPassword = "";
      this.newPassword = "";
      this.passwordConfirmation = "";
    },

    async logoutAccount() {
      var self = this;

      var res = await thestore.postAuthLogout(self.token);
      if (res.success) {
        Cookies.remove("jwt");
        Cookies.remove("usid");

        setTimeout(() => {
          self.$router.push("/auth/login");
        }, 2000);
      }
    },

    async submitForm() {
      var self = this;
      self.isLoading = true;

      try {
        const res = await thestore.postSettingChangePassword(
          self.token,
          self.oldPassword,
          self.newPassword,
          self.passwordConfirmation
        );

        if (res.success) {
          toast.success(res.msg);
          await self.logoutAccount(); // Logout ketika sukses ubah password
        } else {
          toast.warning(res.msg);
          self.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Terjadi kesalahan");
      } finally {
        self.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.form-control .input_l {
  color: #2a2e39 !important;
}
</style>
