<template>
  <!-- card withdraws -->
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Daftar Withdraw</h5>
      <div class="wallet-history">
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="text-left">Waktu</th>
              <th class="text-right">Jumlah</th>
              <th class="text-right">Biaya</th>
              <th class="text-right">TxHash</th>
              <th class="text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in withdraws" :key="item.id">
              <td>{{ item.id }}</td>
              <td>
                {{ formatDate(item.created_at) }}
              </td>
              <td class="text-right">{{ item.withdraw_amount }}</td>
              <td class="text-right">{{ item.withdraw_fee }}</td>
              <td class="text-right">
                {{ item.withdraw_txhash }}
                <button
                  @click="copyToClipboard(item.withdraw_txhash)"
                  class="copy-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="gray"
                      d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                    />
                  </svg>
                </button>
              </td>
              <td class="text-left">{{ item.withdraw_status }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        v-if="withdraws.length > 0 || pagination.currentPage > 1"
        :current-page="pagination.currentPage"
        :last-page="pagination.lastPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import Pagination from "@/components/global/Pagination.vue";

const toast = useToast();

export default {
  components: {
    Pagination,
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      withdraws: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        lastPage: true,
      },
      moment,
    };
  },

  sockets: {
    connect: function () {
      // PERHATIAN: JANGAN DI COMMENT LOG INI KARENA DI PRODUCTION AKAN MENYEBABKAN ERROR
      console.log("socket connected");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        if (parseInt(self.usid) == el.u) {
          self.getWithdraws();
        }
      });
    },
  },

  async mounted() {
    var self = this;
    await self.getWithdraws();
  },

  methods: {
    async getWithdraws() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getWithdraws(
        self.token,
        self.pagination.currentPage,
        self.pagination.itemsPerPage,
        "id",
        "desc",
        filter_rules
      );
      self.withdraws = res.datas;

      if (self.withdraws.length < self.pagination.itemsPerPage) {
        self.pagination.lastPage = true;
      } else {
        self.pagination.lastPage = false;
      }
    },

    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Berhasil disalin ke papan klip!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },

    handlePageChange(page) {
      this.pagination.currentPage = page;
      this.getWithdraws();
    },
  },
};
</script>

<style scoped>
.copy-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 10px;
}
</style>
